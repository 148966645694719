import './src/styles/global.css';
// import './src/styles/styles.css';

import '@fontsource/bebas-neue/400.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/300.css';

import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

export const wrapRootElement = ({ element }) => (
  <ParallaxProvider>{element}</ParallaxProvider>
);
